.main {
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding: 2rem 1rem;
}

.contentWrapper {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.header img {
  width: 100px;
  height: auto;
}

.header h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 1.4;
  color: #333;
  max-width: 500px;
}

.meetingSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.tabContent {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  background: rgba(255, 255, 255, 0.05);
}

.logViewer {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.05);
}

.logList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.logEntry {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.logButton {
  background: none;
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
}

.logButton:hover {
  background: #f5f5f5;
}

.logContent {
  background: #000000;
  color: #ffffff;
  padding: 1rem;
  border-radius: 4px;
  white-space: pre-wrap;
  font-size: 0.9rem;
  max-height: 300px;
  overflow-y: auto;
}
